body { margin: 0; padding: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body{
  position: relative;
  margin: 0;
  padding: 0;
  background-color: #f2f2f2;
}

*{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

#CV{
  width: 100%;
}
.dontTouch{
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 2147483649;
}
.dontTouchContainer{
  position: fixed;
  width: 100%;
  height: 55px;
  z-index: 2147483649;
  inset: auto 0px 0px auto;
}

@import 'styles/loginPanel';
@import 'styles/main';

@import 'styles/jsw2021';