.titlePatern{
  font-family: 'Secular One', sans-serif;
  line-height: 1;
}

.jsw2021{
  .title1{
    @extend .titlePatern;
    font-size: 55px;
    color: #ef7f1a;
    -webkit-text-fill-color: #ef7f1a;
    -webkit-text-stroke: 0.8px #292929;
    @media (max-width: 699px) {
      font-size: 10vw;
    }
  }
  .title2{
    @extend .titlePatern;
    font-size: 37px;
    color: #ef7f1a;
    @media (max-width: 699px) {
      font-size: 32px;
    }
  }
  .title3{
    @extend .titlePatern;
    font-size: 35px;
    color: #ef7f1a;
    @media (max-width: 699px) {
      font-size: 30px;
    }
  }
  .title4{
    @extend .titlePatern;
    font-size: 25px;
    color: #ef7f1a;
  }
  p{
    font-family: 'Lato', sans-serif;
    font-size: 17px;
  }
  ul, ol{
    font-family: 'Lato', sans-serif;
    font-size: 17px;
  }
  h1{
    margin-bottom: 5px;
  }

  .section-text{
    position: relative;
    width: 100%;
    border-bottom: solid #ef7f1a 1px;
    border-top: solid #ef7f1a 1px;
    box-shadow: inset 0px 5px 4px #8c8c8c,
    inset 0px -5px 4px #8c8c8c;
    background-color: #fafff8;
    padding-bottom: 50px;
    b{
      color: #ef7f1a;
    }
    .big-buttons{
      margin: 15px 0;
    }
    .text-container{
      position: relative;
      padding: 15px;
      padding-left: 40px;
      position: relative;
      @media (max-width: 599px) {
        padding-left: 15px;
      }
      ul{
        list-style: none;
        font-size: 17px;
        li{
          margin-bottom: 7px;
        }
      }
      p{
        font-size: 17px;
        padding: 0;
        margin-top: 10px;
      }
      h3{
        margin: 0;
        padding: 10px;
        margin-bottom: 20px;
        border-bottom: solid #ef7f1a 1px;
      }
    }
  }

  .section-text-no-shadow{
    box-shadow: none;
  }


  .twoDivInRow{
    position: relative;
    width: 100%;
    >div{
      position: relative;
      width: 48%;
      @media (max-width: 980px) {
        width: 95%;
      }
    }
    .img-container{
      img{
        height: 100%;
        position: relative;
        overflow: hidden;
      }
    }
  }

  .titlesNnumber-container-box{
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    margin-top: 40px;
    .container-item{
      position: relative;
      margin: 0;
      padding: 0;
      background-color: #ff962e00;
      min-height: 66px;
      border-bottom: solid #ef7f1a 1px;
      .number-div{
        position: absolute;
        top: 0;
        left: 0;
        background-color: #ef7f1a;
        color: #fff;
        text-align: center;
        font-weight: 500;
        font-size: 2.5rem;
        line-height: 1.2;
        width: 95px;
        height: 100%;
        @media (max-width: 599px) {
          position: relative;
          top: auto;
          left: auto;
        }
      }
      .text{
        position: relative;
        font-family: 'Secular One', sans-serif;
        text-align: left;
        font-size: 2rem;
        margin: 0;
        padding: 0;
        width: 100%;
        padding-left: 95px + 10px;
        color: #ef7f1a;
        @media (max-width: 599px) {
          text-align: center;
          padding-left: 0;
          margin-top: 10px;
          font-size: 1.7rem;
        }
      }
    }
  }

  .chat-cointainer{
    position: relative;
    width: 100%;
    height: 650px;
    iframe{
      position: relative;
      width: 100%;
      height: 100%;
    }
    @media (max-width: 900px) {
      height: 60vh;
    }
  }
  .chatango-link{
    display: block;
    padding: 5px 15px;
    margin: 5px 0;
    border-radius: 5px;
    background-color: #cecece;
    outline: none;
    cursor: pointer;
    color: #303030;
    text-decoration: none;
    align-items: center;
    font-weight: 500;
    font-size: 1rem;
    transition: background-color .2s linear,color .2s linear,border-color .2s linear;
    border: solid rgb(90, 90, 90, 0) 1px;
    &:hover {
      border: solid rgb(90, 90, 90, 1) 1px;
      background-color: #ff962e;
      color: #fff;
    }
    &.active{
      border: solid rgb(90, 90, 90, 1) 1px;
      background-color: #ef7f1a;
      color: #fff;
    }
  }

}