.subtitle{
  font-weight: 500;
  font-size: 1.2rem;
}
.subtile{
  font-weight: 500;
  font-size: 1.2rem;
}
.center-text{
  text-align: center;
}
.noWrap{
  white-space: nowrap;
}
.text-main{
  padding: 0;
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}
#gray-info{
  margin: 25px 0;
  color: darkgray;
  font-size: 0.8rem;
  text-align: center;
  text-transform: uppercase;
}

.btnMain1{
  margin: 10px;
  padding: 7px 15px;
  text-decoration: none;
  text-align: center;
  background-color: #ef7f1a;
  border: 3px solid #cc7401;
  font-weight: 400;
  border-radius: 10px;
  font-size: 1.1rem;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color .12s linear, font-weight .12s linear,border-color .12s linear;
  &:disabled{
    background-color: #e9e9e9;
    border: 3px solid #e9e9e9;
    color: #838383;
  }
  &:enabled{
    &:hover {
      border-color: rgb(46, 46, 46);
      background-color: #f1780d;
      font-weight: bold;
    }
  }
  @media (max-width: 1150px) {
    width: 95%;
    padding: 15px 5px;
  }
}

.input-container{
  position: relative;
  max-width: 500px;
  height: 80px;
  margin-top: 35px;
  padding: 10px;
  .label-float{
    font-size: 1em;
    padding: 14px 20px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 20px;
    background-color: #f5f5f5;
    color: black;
    box-shadow: 1px 1px 2px black;
    border: 2px solid #ffffff00;
    transition: 0.2s ease border-color;
    &:focus{
      outline: none;
      border: 2px solid #ef7f1a;
    }
    &:focus ~ label, &:valid ~ label {
      top: -25px;
    }
    &:valid{
      border: 2px solid #439c36;
      box-shadow: 1px 3px 6px rgb(7, 66, 9);
    }
  }
  .floating {
    padding: 12px 20px;
    color: #5A5A5A;
    font-weight: normal;
    font-size: 1em;
    position: absolute;
    pointer-events: none;
    left: 20px;
    top: 13px;
    transition: 0.2s ease top;
    -moz-transition: 0.2s ease top;
    -webkit-transition: 0.2s ease top;
  }
}

.site-boxed{
  position: relative;
  max-width: 1200px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 75px;
  background-color: #fff;
  p{
    @extend .text-main;
  }
  ol, li{
    margin: 0;
    padding: 0;
  }
  h1, h2{
    margin-top: 50px;
    font-weight: 500;
    font-size: 2rem;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
  }

}
.main-site-boxed{
  box-shadow: 0px 1px 10px #8c8c8c;
}
.main-section-jsw20{
  position: relative;
  width: 100%;
  padding: 15px 35px;
  padding-bottom: 55px;
  @media (max-width: 1080px) {
    padding: 1em;
    padding-bottom: 75px;
  }
}
.main-sections-padding{
  position: relative;
  width: 100%;
  padding: 15px 35px;
  padding-bottom: 75px;
  @media (max-width: 1080px) {
    padding: 1em;
    padding-bottom: 75px;
  }
}
.sectionPadding{
  position: relative;
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
}


.list-numbers-title-container{
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  margin-top: 40px;
  .number{
    background-color: #f4981e;
    color: #fff;
    text-align: center;
    font-weight: 500;
    font-size: 2.5rem;
    width: 95px;
    height: 66px;
  }
  .text-container{
    position: relative;
    height: 100%;
    width: 90%;
    @media (max-width: 1180px) {
      width: 70vw;
    }
    @media (max-width: 599px) {
      width: 100%;
    }
    .text{
      position: relative;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1.4rem;
      color: #212529;
      @media (max-width: 599px) {
        text-align: center;
        font-size: 1.2rem;
      }
    }
  }
}

.gallery-2-col{
  position: relative;
  width: 100%;
  .container{
    position: relative;
    width: 48%;
    margin: 3px;
    @media (max-width: 959px) {
      width: 98%;
    }
    .imgContainer{
      position: relative;
      width: 100%;
      overflow: hidden;
      img{
        width: 100%;
      }
    }
  }
}

.big-buttons{
  position: relative;
  width: 100%;
  .button{
    margin: 10px;
    padding: 15px 0px;
    text-decoration: none;
    text-align: center;
    width: 47%;
    background-color: #f4981e;
    border: 3px solid #cc7401;
    font-weight: 400;
    border-radius: 10px;
    font-size: 1.4rem;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color .12s linear, font-weight .12s linear,border-color .12s linear;
    &:hover {
      border-color: rgb(46, 46, 46);
      background-color: #f1780d;
      font-weight: bold;

    }
    @media (max-width: 1150px) {
      width: 95%;
      padding: 15px 5px;
    }
  }
}


.list-titlesNnumber-container-box{
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 40px;
  .container-item{
    position: relative;
    text-decoration: none;
    margin: 0;
    padding: 0;
    background-color: #ff962e00;
    min-height: 66px;
    .number-div{
      position: absolute;
      top: 0;
      left: 0;
      background-color: #f4981e;
      color: #fff;
      text-align: center;
      font-weight: 500;
      font-size: 2.5rem;
      line-height: 1.2;
      width: 95px;
      height: 100%;
    }
    .text{
      width: 100%;
      padding-left: 95px + 10px;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1.8rem;
      color: #212529;
      @media (max-width: 599px) {
        font-size: 1.3rem;
      }
    }
  }
}