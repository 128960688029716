.login-panel{
  width: 100%;
  position: relative;
  h1, h2{
    margin-top: 50px;
    font-weight: 500;
    font-size: 2rem;
    text-align: center;
    text-transform: uppercase;
  }
  h1{
    margin: 0;
    padding-top: 50px;
  }
  form{
    font-size: 20px;
    mat-form-field{
      width: 400px;
      @media (max-width: 599px) {
        width: 90%;
      }
    }
  }
  .bar{
    background-color: #ff5656;
    height: 32px;
    margin-top: 75px;
    margin-bottom: 25px;
  }
  
}